import React from "react";
import { Button, message, Form, Select, Tooltip } from "antd";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import UserTable from "./components/Table.js";
import SearchOrg from "./components/Search.js";

import "./index.less";

const WrapperView = Loader.loadBusinessComponent("SystemWrapper");
const BreadCrumb = Loader.loadBaseComponent("BreadCrumb");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const Search = Loader.loadBaseComponent("SearchInput");

@withRouter
@Decorator.businessProvider("organization", "userManagement", "tab", "roleManagement", "menu")
@Decorator.withEntryLog()
@observer
class UserView extends React.Component {
  state = {
    list: [],
    loading: false,
    ifInclude: 0, // 是否包含子组织
    roleList: [],
    clickedOrgInfo: null,
    selectedRowKeys: [],
    selectedRows: null
  };
  componentWillMount() {
    const { userManagement } = this.props;
    userManagement.initData();
    this.queryRoles();
  }
  componentDidMount() {
    SocketEmitter.on("UPDATE_OrgTree_LIST", this.updateOrgTreeList);
  }
  componentWillUnmount() {
    SocketEmitter.off("UPDATE_OrgTree_LIST", this.updateOrgTreeList);
  }

  async queryRoles() {
    const res = await Service.role.queryRoleList({
      limit: 1000,
      offset: 0
    });
    this.setState({
      roleList: res.data.list
    });
  }

  /**
   * 监听组织更新
   */
  updateOrgTreeList = (dataInfo, type = "emitEvent") => {
    this.leafClk([dataInfo.organizationId], type);
  };

  /**子节点点击事件 */
  leafClk = (key, type) => {
    if (!key || !key.length) {
      return;
    }
    const { userManagement, organization } = this.props;
    const parentOrgList = organization.getParentOrgListByOrgId(key[0]);
    const infoByOrgId = parentOrgList[0];
    if (type && type === "emitEvent") {
      let expandkeys = parentOrgList.map(v => v.id);
      const arr = expandkeys.filter(v => v !== key[0]);
      this.orgTree.onExpand(arr);
    }
    userManagement.setData({
      activeKey: key
    });
    this.getUserList({ offset: 0 });
    this.setState({
      clickedOrgInfo: infoByOrgId ? infoByOrgId.id : ""
    });
  };

  /**查询 */
  getUserList = async (filters = {}) => {
    const { userManagement } = this.props;
    this.setState({ loading: true });
    userManagement.editSearchData(filters);
    let searchData = userManagement.searchData;
    searchData.organizationId = userManagement.activeKey[0];
    const userResult = await Service.user.queryUsers(searchData);
    let { list, total } = userResult.data;
    const userIds = list.map(v => v.id);
    const result =
      list.length > 0 ? await Promise.all([Service.user.getIdentityCard(userIds), Service.user.getMobile(userIds)]) : null;
    const identityCardNums = result ? result[0].data : [];
    const phoneNums = result ? result[1].data : [];
    list.forEach((v, k) => {
      const idCardItem = identityCardNums.find(x => x.id === v.id);
      const PhoneItem = phoneNums.find(p => p.id === v.id);
      v.identityCardNum = idCardItem.identityCardNum;
      v.phoneNum = PhoneItem.mobile;
    });
    this.setState({
      list,
      total,
      loading: false
    });
  };
  /**删除弹窗确定 */
  deleteOk = item => {
    const { searchData } = this.props.userManagement;
    return Service.user
      .deleteUser(item)
      .then(() => {
        this.getUserList({
          offset:
            this.state.list.length === 1 && searchData.offset !== 0 ? searchData.offset - searchData.limit : searchData.offset
        });
        return Promise.resolve();
      })
      .catch(err => {
        message.error(err.data && err.data.message);
        return Promise.reject();
      });
  };

  /** 包含子组织*/
  changeSearchData = ({ value }) => {
    this.setState({
      ifInclude: value
    });
    this.editSearchData({ containSubOrganization: value, offset: 0 });
  };
  /** 查询条件改变并查询*/
  editSearchData = options => {
    this.props.userManagement.editSearchData(options).then(this.getUserList);
  };
  /**分页切换查询 */
  onPageChange = (pageNum, pageSize) => {
    this.editSearchData({
      limit: pageSize,
      offset: (pageNum - 1) * pageSize
    });
  };
  keyWordsSearch = searchFilter => {
    this.editSearchData({
      keywords: searchFilter,
      offset: 0
    });
  };
  /**新增用户 */
  goPage = ({ moduleName, data, state }) => {
    this.props.tab.closeTabForName(moduleName);
    this.props.tab.goPage({
      moduleName,
      location: this.props.location,
      isUpdate: false,
      data,
      state
    });
  };
  handleAddUser = () => {
    this.goPage({
      moduleName: "userNew"
    });
  };
  changeStatus = item => {
    Service.user.changeUserStatus(item.id, item.validState == 104406 ? 104405 : 104406, item.realName).then(this.getUserList);
  };
  /** 批量删除操作*/
  deleteGroup = () => {
    const { selectedRowKeys, selectedRows } = this.state;
    this.deleteGroupInfo.deleteGroup(selectedRowKeys, selectedRows);
  };
  render() {
    const { organization, userManagement } = this.props;
    let { list, total, loading, ifInclude, roleList } = this.state;
    let allRoleList = [].concat([{ id: "", roleName: "全部角色" }], roleList);
    const { searchData, activeKey } = userManagement;
    const BreadcrumbList = organization.getParentOrgListByOrgId(activeKey[0]).reverse();
    return (
      <WrapperView
        allowClear={true}
        treeActiveKey={activeKey}
        leafClk={this.leafClk}
        viewRef={orgTree => (this.orgTree = orgTree)}
        Treetitle="用户管理"
        width="85%"
        TreeChildren={<SearchOrg changeSearchData={this.changeSearchData} ifInclude={ifInclude} />}
        leftOrgTree={true}
        className="user-setting-wrapper"
        breadCrumb={activeKey && <BreadCrumb list={BreadcrumbList} />}
      >
        <div className="user-content">
          <div className="user-Search-Btns">
            <div className="user-search-container">
              <AuthComponent actionName="userNew">
                <Button type="primary" onClick={this.handleAddUser} icon="plus" className="orange-btn setting-user-add">
                  新建用户
                </Button>
              </AuthComponent>
              <FormGroupLayout className="user-filters" roleList={allRoleList} getUserList={this.getUserList} />
            </div>
            <Tooltip title="请输入用户名或手机号查询">
              <Search
                placeholder="请输入用户名或手机号查询"
                style={{ width: "250px", height: "28px" }}
                enterButton
                onChange={this.keyWordsSearch}
              />
            </Tooltip>
          </div>
          <UserTable
            deleteRef={deleteGroup => {
              this.deleteGroupInfo = deleteGroup;
            }}
            className="user-content-table"
            deleteOk={this.deleteOk}
            dataSource={list}
            loading={loading}
            total={total}
            searchData={searchData}
            onChange={this.onPageChange}
            changeStatus={this.changeStatus}
            scroll={{ y: "100%" }}
            roleList={roleList}
            goPage={this.goPage}
          />
        </div>
      </WrapperView>
    );
  }
}

const FormItem = Form.Item;
const Option = Select.Option;
const userStatus = [
  {
    value: "",
    label: "全部状态"
  },
  {
    value: 104406,
    label: "启用"
  },
  {
    value: 104405,
    label: "禁用"
  },
  {
    value: 104400,
    label: "过期"
  }
];
@withRouter
@Decorator.businessProvider("userManagement")
@observer
@Form.create({
  onFieldsChange: (props, files) => {
    const { userManagement } = props;
    let data = {};
    Object.keys(files).map(key => {
      data[key] = files[key].value;
    });
    userManagement.editSearchData(data);
    props.getUserList({ offset: 0 });
  }
})
class FormGroupLayout extends React.Component {
  render() {
    const { form, roleList, className = "" } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form layout="inline" className={className}>
        <FormItem>
          <Tooltip title="全部角色">
            {getFieldDecorator("role")(
              <Select placeholder="全部角色">
                {roleList &&
                  roleList.map(item => (
                    <Option key={item.id} value={item.id} title={`${item.roleName}`}>
                      {item.roleName}
                    </Option>
                  ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
        <FormItem>
          <Tooltip title="全部状态">
            {getFieldDecorator("validState")(
              <Select placeholder="全部状态">
                {userStatus &&
                  userStatus.map(item => (
                    <Option key={item.value} value={item.value} title={`${item.label}`}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            )}
          </Tooltip>
        </FormItem>
      </Form>
    );
  }
}
export default UserView;
